import React from 'react';
import Png from "./Png";

const ErrorComponent = () => {
    return (
        <div className="chat">
            <div className="chat__log">
                <div className="container">
                    <div className="chat__log--not-started">
                        <div className="chat__log-notice">
                            <Png
                                name="ic-video-camera-disabled"
                                className="img-responsive"
                                alt="appointment not ready"
                                width="150"
                                />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorComponent;