import { confOptions, initOptions } from "./options";

class OKAJitsi {

    connection = null; 

    isJoined = false; 

    room = null;

    jitsiId = null;

    appointmentNumber  = '';

    user = null;

    constructor({appointmentNumber, user, roomUrl, isApp}) {
        if (!window.JitsiMeetJS) return null; 

        this.appointmentNumber = appointmentNumber; 
        this.user = user;

        const { JitsiMeetJS } = window;

        const isEmptyRoomUrl = roomUrl === '';
        
        const options =  {
            hosts: {
                domain: !isEmptyRoomUrl ?  roomUrl : 'meet-uae.okadoc.com',
                muc: !isEmptyRoomUrl ? `conference.${roomUrl}` : 'conference.meet-uae.okadoc.com',
            },
            bosh: !isEmptyRoomUrl ? `https://${roomUrl}/http-bind` : 'https://meet-uae.okadoc.com/http-bind',
            clientNode: 'http://jitsi.org/jitsimeet'
        }

        JitsiMeetJS.init(initOptions);
        this.connection = new JitsiMeetJS.JitsiConnection(null, null, options);   
        
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            this.onConnectionSuccess
        );

        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            this.onConnectionFailed
        );

        if (isApp) {
          this.connection.addEventListener(
              JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
              () => {
                setTimeout(function(){
                  window.location.reload();
                });
              }
          );          
        }

        this.connection.connect();
    }

    /**
     * That function is called when connection is established successfully
     */
    onConnectionSuccess = () =>  {
        const roomName = this.appointmentNumber.toLowerCase().replace("-", "");
        this.room = this.connection.initJitsiConference(roomName, confOptions);
        this.room.join();
        this.room.setDisplayName(this.user.getNickname());

        this.jitsiId = this.room.myUserId();
    }

    /**
     * This function is called when the connection fail.
     */
    onConnectionFailed() {
        console.error('Connection Failed!');
    }

    getRoom = () =>  {
        return this.room;
    }

    getJitsiId = () => {
        return this.jitsiId;
    }

    getConnection = () => {
        return this.connection;
    }

    onConnected = () => {
        return new Promise((resolve) => {
            const waitForRoom = () => {
                const { room } = this;
                if (room) return resolve(room);
                setTimeout(waitForRoom, 30);
            };
            waitForRoom();
        });
    }

}


export default OKAJitsi;