import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
let theDate = day.extend(utc);
theDate = theDate.extend(advancedFormat);
theDate = theDate.extend(localizedFormat);
theDate = theDate.extend(relativeTime);

export const sec2time = (timeInSeconds) => {
    var pad = function(num, size) { return ('000' + num).slice(size * -1); },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60);

    return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
}

export const second_in_minute = 60;
export const second_in_hour = 60 * second_in_minute;
export const second_in_day = 24 * second_in_hour;

export const getRemainingTime = (to) => {
    const now = theDate().local();
    const diffc = to.unix() - now.unix();
    let seconds = diffc;
    let day = 0;
    let hours = 0;
    let minutes = 0;
    if (diffc > second_in_day) {
        day = Math.floor(seconds / second_in_day);
        seconds = seconds % (day * second_in_day);
    }
    if (seconds > second_in_hour) {
        hours = Math.floor(seconds / second_in_hour);
        seconds = seconds % (hours * second_in_hour);
    }

    minutes = Math.ceil(seconds / second_in_minute);
    seconds = seconds % (minutes * second_in_minute);
    
    const r =  {
        day: day,
        hour: hours,
        minute: minutes,
        second: seconds,
        seconds: diffc
    };
    return r;
}

export default theDate;
