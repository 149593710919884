import React, { useCallback, useMemo } from 'react';
import Png from "./Png";
import { sendMessage } from '../libs/message';
import cs from 'classnames';
import InnerHTML from 'dangerously-set-html-content';


const MessageOnMobile = (props) => {
    const { blockContent } = props;
    const content = blockContent.mobile || null;

    if (!content) return null;

    return (
        <InnerHTML html={content} />
    )
}

const MessageOnDesktop = (props) => {
    const { blockContent } = props;
    const content = blockContent.desktop || null;

    if (!content) return null;

    return (
        <InnerHTML html={content} />
    )
}

const BrowserNotSupported = (props) => {
    const { t, domain, keyCode } = props;

    const onBackButtonClick = useCallback(() => {
        sendMessage({
            'type': 'backSession'
        }, domain);
    }, [domain]);

    const isWhitelabel = useMemo(() => (keyCode && (keyCode !== '')), [keyCode]);
    const chatHeaderClass = useMemo(() => {
        return cs({
            'chat-nav': true,
            'chat-py-8': isWhitelabel
        });
    }, [isWhitelabel]);

    const iconContent = useMemo(() => {
        if (isWhitelabel) {
            return (
                <button className="button clear align-items-center" onClick={onBackButtonClick}>
                    <Png name="arrow-left" alt="back arrow" width="10px" />
                </button>
            );
        }

        return (
            <a href="/">
                <Png
                    name="logo-gradient"
                    alt="logo okadoc"
                    width="120"
                    className="img-responsive"
                />
            </a>
        );
    }, [isWhitelabel, onBackButtonClick]);

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    return (
        <div className="chat bg-gray">
            <div className={chatHeaderClass}>
                <div className="container">
                    <div className="chat-nav__row">
                        <div className="chat-nav__col">
                            {iconContent}
                        </div>
                        <div className="chat-nav__col justify-content-end">
                            <p className="d-flex align-items-center">
                                <span style={{ marginRight: "10px" }}>{t('Video Consultation')}</span>
                                <Png
                                    name="ic-video-call-purple"
                                    alt="video camera"
                                    width="20"
                                    className="img-responsive"
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="browser-notice">
                <div className="container">
                    {
                        isMobile ? <MessageOnMobile {...props} /> : <MessageOnDesktop {...props} />
                    }
                </div>
            </div>
        </div>
    )
}

export default BrowserNotSupported;