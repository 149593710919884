import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import to from 'await-to-js';
import qs from 'qs';
import dayjs from 'dayjs';
import _isEmpty from  'lodash/isEmpty';
import _get from 'lodash/get';
import utc from 'dayjs/plugin/utc';
import Loading from '../../components/Loading';
import BrowserNotSupported from '../../components/browserNotSupported';
import ErrorComponent from '../../components/error';

import { getParams } from '../../libs/url';
import getOS from '../../libs/getOS';
import getThemes from '../../libs/themes/getThemes';
import { sendMessage } from '../../libs/message';
import { isFirefox, isOkadocApp, isChrome, chromeVersion } from "../../libs/okaBrowserCheck";
import { isValidDomain, isDoctorDomain } from '../../libs/domain';
import { getToken } from '../../libs/cookies';

import Chat  from '../Chat';
import { getBrowserNotSupportBC, getDetailAppointment, initUser, constanta, saveChat, getAppInit, getDetailTelemedicine } from './actions';

const dayUtc = dayjs.extend(utc);

class Widget extends React.Component {

    state = {
        showModal: false,
        appointment: false,
        errAppointment: false,
        blockContent: [],
        loading: true, 
        appointmentNumber: 'session1',
        platform: "",
        devices: [],
        country: 'ae',
        lang: 'en',
        file: false,
        fileContent: false,
        started: true,
        messages: [],
        initData: false,
        roomAttr: {},
        authToken:  '',
    }

    componentDidMount() {
        const { location } = this.props;
        const params = getParams(location.search);
        const hostname = typeof window !== 'undefined' && window.location.hostname;
        const token = getToken();
        const { domain, keycode } = params;

        this.setState({
            platform: getOS()
        });

        const isLocalhost = hostname === 'localhost';
        const isOkadocApp = /okadoc/i.test(window.navigator.userAgent.toLowerCase());
        const isOutsystem = keycode && (/daman|bupa|mednet/i).test(keycode);
        const isInApp = isOkadocApp || isOutsystem;

        // send post message to parent for validation ready
        if (isInApp) {
            sendMessage({ type: 'document_ready' }, false, true);
        } else {
            sendMessage({ type: 'document_ready' }, isLocalhost ? false : domain);
        }
        // end send post message to parent for validation ready

        // for development used only and must have parameter
        // for get token in cookies please login in whitelabel first with domain localhost
        // please run whitelabel under localhost
        if (location.search && isLocalhost && token) {
            this.init(token);
        }
        // end for development used only

        window.addEventListener("message", (event) => {
            this.loadData(event);
        });
    }

    componentWillUnmount() {
        window.removeEventListener("message", (event) => {
            this.loadData(event);
        });
    }

    loadData = (event) => {
        const { location } = this.props;
        const params = qs.parse(location.search.split("?")[1]);
        const { domain } = params;

        const originUrl = new URL(event.origin);
        if (originUrl.hostname === domain) {
            try {
                const data = JSON.parse(event.data);

                if (data.type === 'SENT_TOKEN') {
                    this.init(data.value);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    init= async(token)  => {
        const {location} = this.props;

        const params = qs.parse(location.search.split("?")[1]);

        const { keycode, domain, id, country = 'ae', lang= 'en' } = params;

        // initiate i18 for translation
        this.props.onStart(country, lang, token);

        const isDoctor = isDoctorDomain(domain);

        // initiate app
        const [, respInit] = await to(getAppInit(keycode, country, lang));

        const initData = _get(respInit, 'data.data');


        // get block content & appointment data
        const [, blockContent] = await to(getBrowserNotSupportBC({
            token,
            whitelabelCode: keycode
        }));

        const [errAppointment, appointmentData= {}] = await to(getDetailAppointment(id, token, isDoctor, { lang, country }));

        let [errRoom, roomAttr = {} ] = await to(getDetailTelemedicine(id, token));
        if (errRoom) {
            roomAttr = {};
        }
        

        let usernya = null;
        if (!_isEmpty(appointmentData)) {
            const type = isDoctor ? 'doctor' : 'patient'; 
            usernya = initUser(type, appointmentData);
        }

        // this.getMessageHistory(id, at);

        this.setState({
            initData,
            appointment: appointmentData,
            blockContent,
            loading: false,
            appointmentNumber: appointmentData.id || '',
            domain: isValidDomain(params.domain),
            user: usernya,
            authToken: token,
            rated: appointmentData.fg_feedback_filled,
            errAppointment: errAppointment,
            country: params.country || 'ae',
            lang,
            roomAttr
        });
    }



    render() {
        const { location: { search } } = this.props;
        const params = getParams(search);
        const { keycode, domain } = params;

        const { errAppointment, appointment, loading,  file, fileContent, done, started, user, sessionClosed, 
                rated, remoteJoined, appointmentNumber,
                platform, blockContent, country, authToken, photo, showModal, initData, roomAttr, lang
        } = this.state;

        const domainIsValid = process.env.REACT_APP_WHITELIST_DOMAINS.includes(domain);
        const isApp = isOkadocApp() || (keycode && (/daman|bupa|mednet/i).test(keycode)) || domainIsValid;
        const notAllowedChromeVersion = chromeVersion() < 70;

        const whitelabelConfigs = _get(initData, 'settings.whitelabel_configs');
        const themes = getThemes(whitelabelConfigs);

        const isRtl = lang === 'ar';
        const rtlStyle = `
            body {
                direction: rtl;
            }
            .chat__input-send img,
            .chatbox-header-menu .button.clear img {
                transform: rotate(180deg);
            }
            .ml-1 {
                margin-left: 0px;
                margin-right: 5px;
            }
            .chatbox-header-menu__button-group > :not(:last-child) {
                margin-right: 0px;
                margin-left: 10px;
            }
            .chat-message__content-call--icon {
                padding-right: 0;
                padding-left: 10px;
            }
            .chat-message__content-time {
                text-align: left;
            }
            .chat-message__content {
                border-radius: 10px 10px 0px 10px;
            }
            .chat-message.current-user .chat-message__content {
                border-radius: 10px 10px 10px 0px;
            }
            .chat-attachment__preview-clear {
                right: auto;
                left: 0;
            }
            .chat-attachment__preview .thumbnail {
                margin-right: 0px;
                margin-left: 10px;
            }
            .video-call__action button:not(:last-child) {
                margin-right: 0px;
                margin-left: 30px;
            }
        `;

        const doctorWeb = user && user.getType() === constanta.PRACTITIONER;

        if (
            !isApp && 
            (
                (
                    // reject when platform is iOS and android
                    (platform === 'ios' ) || (platform === 'android' ) ||
                    // will reject when platform Windows & browser is not chrome  
                    (
                        platform === 'windows' && !isChrome()  && 
                        platform === 'windows' && !isFirefox()
                    )

                    // enable all browser for macos
                    /* || (
                        (platform === 'macos' && !isChrome() ) &&
                        (platform === 'macos' && !isFirefox() )
                    )*/
                ) ||
                // check when chrome below  version 70
                (isChrome() && notAllowedChromeVersion)
            )
        ) {
            return (
                <BrowserNotSupported 
                    {...this.props} 
                    keyCode={keycode}
                    blockContent={blockContent}
                />
            );
        }

        const { t } = this.props;
        // const doctorWeb = user && user.getType() === constanta.PRACTITIONER;
        if (errAppointment) return (
            <ErrorComponent />
        );

        const userSessionIsDone = sessionClosed || done;
        const notStartedYet = dayUtc(appointment.start).local().unix() >= dayUtc().local().unix();


        return (
            <Fragment>
                {loading && (
                    <Loading t={t}/>
                )}
                {themes.font_url &&
                    <Fragment>
                        <link href={themes.font_url} rel='stylesheet' type='text/css' />
                    </Fragment>
                }
                {isRtl &&
                    <style>
                        {rtlStyle}
                    </style>
                }
                {!loading && (
                    <Chat.Box
                        {...this.props}
                        themes={themes}
                        country={country}
                        t={t}
                        appointment={appointment}
                        started={started}
                        // messages={messages}
                        user={user}
                        loading={loading}
                        rated={rated}
                        file={file}
                        keycode={keycode}
                        doctorWeb={doctorWeb}
                        fileContent={fileContent}
                        setFile={this.setFile}
                        setFileContent={this.setFileContent}
                        sessionClosed={userSessionIsDone}
                        notStartedYet={notStartedYet}
                        remoteJoined={remoteJoined}
                        authToken={authToken}
                        roomAttr={roomAttr}
                        isApp={isApp}
                        openPhoto={(photo) => {
                            this.setState({
                                photo,
                                showModal: true
                            });
                        }}
                        onNotification={(e) => {
                            this.getMessageHistory();
                        }}
                        onSaveChat={async(message) => {
                            if (!done) {
                                await saveChat(appointmentNumber, message, authToken);
                            }
                        }}
                        onRateButtonClick={() => {
                            sendMessage({
                                type: 'rate'
                            }, domain);
                        }}
                        onErrorAttachment={(e, m) => {
                            this.setState({
                                showError: true,
                                message: m
                            });
                        }}
                        />
                    
                )}

                <Chat.Photo
                    t={t}
                    photo={photo}
                    show={showModal}
                    closeModal={() => {
                        this.setState({
                            photo: undefined,
                            showModal: false,
                        });
                    }}
                    />

            </Fragment>
        )
    }

}

export default withRouter(Widget);

