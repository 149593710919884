import React, { Fragment, useRef, useImperativeHandle, forwardRef } from 'react';

const FileInput = forwardRef((props, ref) => {
    const { onChange = null, children, ...rest } = props;
    const fileRef = useRef();

    useImperativeHandle(ref, () => ({
        clearFile: () => {
            fileRef.current.value = '';
        },
        browseFile: () => {
            fileRef.current.click();
        }
    }));

    const handleUpload = (event) => {
        const file = event.target.files[0];

        if (typeof onChange === 'function') {
            onChange(file);
        }

        fileRef.current.value = null;
    };

    return (
        <Fragment>
            <input
                type="file"
                style={{ display: 'none' }}
                onChange={handleUpload}
                ref={fileRef}
                {...rest}
            />
            {children}
        </Fragment>
    );
});

export default FileInput;