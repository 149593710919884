import React, { Component, Fragment } from 'react';
export default class VideoComponent extends Component {

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidUpdate(props) {
        if (props && props.streamManager && !!this.videoRef) {
            this.props.streamManager.addVideoElement(this.videoRef.current);
        }
    }

    componentDidMount() {
        if (this.props && this.props.streamManager && !!this.videoRef) {
            this.props.streamManager.addVideoElement(this.videoRef.current);
        }
    }

    getNicknameTag() {
        // Gets the nickName of the user
        return JSON.parse(this.props.streamManager.stream.connection.data).clientData;
    }

    render() {
        const { muted } = this.props;
        return (
            <Fragment>
                {this.props.streamManager !== undefined ? (
                    <div className="stream-component">
                        <video className="stream-component__video" autoPlay={true} ref={this.videoRef} id={this.props.videoID} />
                        {/* Show this when video is muted */}
                        {muted && (
                            <div className="stream-component__placeholder">
                                <div className="stream-component__user">
                                    <div className="thumbnail">
                                        <img
                                            alt="doctor profile"
                                            className="thumbnail__image"
                                            src="http://placekitten.com/200/300"
                                        />
                                    </div>
                                    <h4 className="text-white">{this.getNicknameTag()}</h4>
                                </div>
                            </div>
                        )}
                    </div>
                ) : null}
            </Fragment>
        );
    }
}
