import Service from './service';

export default class Pigeon extends Service {

    constructor(options = {}) {
        options.name = 'pigeon';
        // default base url for pigeon api
        options.baseURL = process.env.REACT_APP_PIGEON_API_URL;
        super(options);
    }

    // add others methods here...
}