import axios from 'axios';
import to from 'await-to-js';
import { log } from './domain';
import Hashes from 'jshashes';
import { ApiRequest } from './services';

const publicSecret = ['p3z4l','f4124h','k3n41200k'];
const randomId = Math.floor(Math.random() * publicSecret.length);

const defaultAppointmentDetail = {
    id: '',
    patient: {},
    doctor: {}
};

const createSession = (sessionId) => {
    return new Promise((resolve, reject) => {
        var data = JSON.stringify({ customSessionId: sessionId });
        axios
        .post(process.env.REACT_APP_OPENVIDU_SERVER_URL + '/api/sessions', data, {
            headers: {
                Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + process.env.REACT_APP_OPENVIDU_SERVER_SECRET),
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            resolve(response.data.id);
        })
        .catch((response) => {
                var error = Object.assign({}, response);
                if (error.response && error.response.status === 409) {
                    resolve(sessionId);
                } else {
                    console.warn(
                        'No connection to OpenVidu Server. This may be a certificate error at ' +
                        process.env.REACT_APP_OPENVIDU_SERVER_URL,
                    );
                    if (
                        window.confirm(
                            'No connection to OpenVidu Server. This may be a certificate error at "' +
                            process.env.REACT_APP_OPENVIDU_SERVER_URL +
                            '"\n\nClick OK to navigate and accept it. ' +
                            'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
                            process.env.REACT_APP_OPENVIDU_SERVER_URL +
                            '"',
                        )
                    ) {
                        window.location.assign(process.env.REACT_APP_OPENVIDU_SERVER_URL + '/accept-certificate');
                    }
                }
            });
    });
}

const createToken = (sessionId) => {
    return new Promise((resolve, reject) => {
        var data = JSON.stringify({ session: sessionId });
        axios
            .post(process.env.REACT_APP_OPENVIDU_SERVER_URL + '/api/tokens', data, {
                headers: {
                    Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + process.env.REACT_APP_OPENVIDU_SERVER_SECRET),
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                resolve(response.data.token);
            })
            .catch((error) => reject(error));
    });
};


const oVGetToken = (sessionID) => {
    log('Get Token OV');
    return createSession(sessionID).then(async(retSession) => {
        const [err, token] = await to(createToken(retSession));
        if (err) {
            throw err;
        }
        return {
            session_token: token
        };
    });
}

const getInit = async(options) => {
    const d = new Date();
    const str = process.env.REACT_APP_CLIENT_ID+d.getUTCHours()+d.getUTCMinutes()+d.getUTCSeconds()+publicSecret[randomId]
    const xToken = new Hashes.SHA256().hex_hmac('f4124hp354l',str);
    const defaultHeader = getDefaultHeader();
    defaultHeader['X-API-Token'] = xToken;
    defaultHeader['x-api-time'] = d.toISOString();

    options.headers = {
        ...options.headers,
        ...defaultHeader
    }
    const [err, result] = await to(ApiRequest.Locale.get(`/appservice/init`, {
        ...options
    }));
    if (err) {
        throw new Error(err);
    }
    return result;
}

const getBlockContent = async(keys, token, options) => {
    const [err, result] = await to(ApiRequest.Locale.get(`/static/get/bulk?keys=${keys}`, {
        headers: getDefaultHeader(token),
        ...options
    }));
    if (err) {
        throw new Error(err);
    }
    return result;
}

const getAppointmentDetail = async(appointmentNumber, token, who, options) => {
    console.log('adadada', options);
    log('getAppointmentDetail', JSON.stringify({
        appointmentNumber, 
        token, 
        who, 
        options
    }));
    let endPoint = `/patientapp/appointments/${appointmentNumber}`;
    if (who === 'practitioner') {
        endPoint = `/doctorapp/appointment/${appointmentNumber}`;
        defaultAppointmentDetail.doctor = {
            id: '',
            title: '',
            profilePicture: '',
            bi: ''
        }
    }
    const [err, result] = await to(ApiRequest.Appointment.get(`${endPoint}`, {
        headers: getDefaultHeader(token, options.country, options.lang)
    }));

    if (err) {
        throw new Error(err);
    }

    if (result && result.data) {
        log('getAppointmentDetail: result', JSON.stringify({
            result
        }));
        if (who === 'practitioner') {
            return {
                ...defaultAppointmentDetail,
                ...result.data.data
            };
        }
        return {
            ...defaultAppointmentDetail,
            ...result.data
        };
    }

    throw new Error('Invalid Appointment ID');
}

const getDefaultHeader = (token, country = 'ae', lang = 'en') => {
    const customHeader = {};

    if (token) {
        customHeader['Authorization'] = `Bearer ${token}`;
    }

    if (country) customHeader['Country-ID'] = country;

    if (lang) customHeader['Accept-Language'] = lang;

    return customHeader;
}

const getLanguage = async(country, lang, token, options) => {
    log('getLanguage', JSON.stringify({
        country, lang, token, options
    }));

    const [err, resp] = await to(ApiRequest.Locale.get(`/translation`, {
        headers: {
            ...getDefaultHeader(token, country, lang)
        },
        ...options
    }));

    if (err) {
        throw err.response.data;
    }
    log('getLanguage: result', JSON.stringify({
        resp
    }));

    return resp.data;
}

const apiGetToken = async(appointmentNumber, token, options) => {
    log('apiGetToken', JSON.stringify({
        appointmentNumber,
        token,
        options
    }));

    const [err, resp] = await to(ApiRequest.Appointment.post(`/telemedicine/session/join`, {
        "appointment_number": appointmentNumber
    }, {
        headers: getDefaultHeader(token),
        ...options
    }));

    if (err) {
        throw err.response.data;
    }

    log('apiGetToken: result', JSON.stringify({
        resp
    }));
    return resp.data.data;
}

const getDetailedRoom = async(appointmentNumber, token) => {
    const config = {
        headers: getDefaultHeader(token)
    };
    const [err, result] = await to(
        ApiRequest.Appointment.get(`/telemedicine/${appointmentNumber}`, config)
    );

    let isSuccess = true;
    if (err) {
        isSuccess = false;
        throw new Error(err);
    }

    return { 
        isSuccess, 
        joinUrl: result.data.data.join_url || process.env.REACT_APP_DEFAULT_JITSY_URL
    };
}


const getCancelToken = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return [source.token, source.cancel];
}

const getChatHistory = async(appointmentNumber, token) => {
    log('getChatHistory', JSON.stringify({
        appointmentNumber, token
    }));

    const params = {
        appointment_number: appointmentNumber
    };

    const config = {
        headers: getDefaultHeader(token),
        params
    };
    const [err, resp] = await to(ApiRequest.Appointment.get(`/telemedicine/chat/history`, config));

    if (err) {
        throw new Error(err);
    }

    const allMessages = resp.data.data || []; 
    const filteredMessage = [];
    allMessages.map((value, index) => {
        if (value.format === 'session_join' || value.format === 'session_start' || value.format === 'session_end') {
            const exising = filteredMessage.find( e => e.format === value.format);
            if (!exising) {
                filteredMessage.push(value);    
            }
        }else {
            filteredMessage.push(value);
        }
        return value;
    });

    return filteredMessage;
}

const saveChatHistory = async(appointmentNumber, message, token, options) => {
    const postData = {
        "appointment_number": appointmentNumber,
        "format": message.format,
        "text": message.text,
        "time": message.time
    };
    const [err, ] = await to(ApiRequest.Appointment.post(`/telemedicine/chat/save`, postData, {
        headers: getDefaultHeader(token),
        ...options
    }));

    if (err) {
        throw new Error(err);
    }
    return true;
}

const startRecording = async(appointmentNumber, token, config) => {
    config = {
        ...config,
        headers: getDefaultHeader(token)
    };
    const [err,] = await to(ApiRequest.Appointment.post(`/telemedicine/session/recording/start`, {
        appointment_number: appointmentNumber
    }, config));
    
    if (err) {
        throw new Error(err);
    }
    return true;
}

const stopRecording = async(appointmentNumber, token, config) => {
    config = {
        ...config,
        headers: getDefaultHeader(token)
    };
    const [err,] = await to(ApiRequest.Appointment.post(`/telemedicine/session/recording/end`, {
        appointment_number: appointmentNumber
    }, config));

    if (err) {
        throw new Error(err);
    }

    return true;
}

const uploadImage = async(appointmentNumber, image, token, config) => {
    config = {
        ...config,
        headers: getDefaultHeader(token)
    };
    const [err, resp] = await to(ApiRequest.Appointment.post(`/telemedicine/chat/image`, {
        "appointment_number": appointmentNumber,
        "image": image
    }, config));

    if (err) {
        throw new Error(err);
    }

    return resp;
}

const getToken = +process.env.REACT_APP_API_READY === 1 ? apiGetToken : oVGetToken;
export {
    getToken,
    getDefaultHeader,
    getCancelToken,
    getChatHistory,
    saveChatHistory,
    startRecording,
    stopRecording,
    getAppointmentDetail,
    uploadImage,
    getLanguage,
    getBlockContent,
    getInit,
    getDetailedRoom
}