import React, { Component, Fragment } from 'react';
import FilePicker from '../../components/FilePicker';
import _isEmpty from "lodash/isEmpty";
import to from 'await-to-js';
import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _uniqueId from 'lodash/uniqueId';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime     from 'dayjs/plugin/relativeTime';


import Png from '../../components/Png';
import Header from './chatboxHeader';
import { sendMessage } from '../../libs/message';
import { getParams } from '../../libs/url';
import Photo from './ModalPhoto';
import Video from './videoCall';
import MessageComponent, { ConsultationPrivacy, ConsultationLoading } from './Message';
import NotStartedYet from '../../components/NotStartedYet';
import OKAJitsi from '../../libs/jitsi';
import { ButtonGradient } from '../../libs/themes';
import { onUploadImage, joinSession, getMessageHistory } from './actions';
import dayUtc from '../../libs/date';
import { getJistsiInAppURL, getJistsiURL, isInAppIframe } from '../../libs/jitsi/url';
import _get from 'lodash/get';

let theDate = day.extend(utc);
theDate = theDate.extend(advancedFormat);
theDate = theDate.extend(localizedFormat);
theDate = theDate.extend(relativeTime);




const mouse = [
    "click",
    "auxclick",
    "contextmenu",
    "dblclick",
    "wheel",
    "select"
];

const touch = [
    "touchcancel",
    "touchend",
    "touchmove",
    "touchstart"
];


const keyboard = [
    "keydown",
    "keyup",
    "keypress"
];

class ChatComponent extends Component {
    
    chatContainer = undefined;

    saveChatHistoryRequest = false;

    checking = false;

    constructor(props) {
        super(props);
        this.state = {
            done: false, 
            loading: false,
            messageList: [],
            message: '',
            messages: [],
            loaded: false,
            binded: false,
            uploading: false,
            file: false,
            fileContent: false,
            started: true,
            isJoinSession: false,
            isTyping: false,
            isTextAreaEnabled: false,
            isEndConversation: false
        };
        this.chatScroll = React.createRef();
        this.request = [];
    }

    componentDidMount() {
        const { loading } = this.props;

        const { done } = this.state;

        this.checking = setInterval(() => {
            this.checkStatus();
        });

        if (!done) {
            this.joinRoom();

        } else {
            this.scrollToBottom();  
        }        

        this.setState({
            done, loading
        });
        window.removeEventListener('message', this.clicked);
        window.addEventListener('message', this.clicked = (e) => {
            this.onGetMessageFromParent(e);
        });
    }

    onGetMessageFromParent= (e) => {
        let message;
        try {
            message = JSON.parse(e.data);
        } catch (error) {
            message = {
                type: ''
            };
        }
        
        if (message.type === 'endingSession') {
            const messageData = { 
                format: 'endingSession',
                ...message.data
            }
            this.chatRoomPublisher(messageData, false);
        }
    }

     getMessageHistory = async (appointmentNumber, token) => {
       const [, messages] =  await to(getMessageHistory(appointmentNumber, token));  
       if (messages)  {
           this.setState({
               messageList: messages
           });
       }
    }

    joinRoom = async () => {
        const { isJoinSession } = this.state;
        if (!isJoinSession) {
            const { appointment: { id }, user, authToken, isApp, roomAttr } = this.props;
            const  [err, ]  = await to(joinSession(id, authToken));
            if (err || !roomAttr.isSuccess) {
                const willUpdated  =   {
                    loading: false, 
                    isJoinSession:  false,
                    started: false
                };

                this.getMessageHistory(id, authToken);
    
                if(err.message.search('again') > -1){
                    willUpdated.done = true;
                }
    
                this.setState(willUpdated)
                return;
            }
    
            const postData = {
                "appointment_number": id,
                "format": 'session_join',
                "sender": user.getType(),
                "text": user.getNickname(),
                "time": new Date().toISOString()
            };
            this.props.onSaveChat(postData);

            this.chatContainer = new OKAJitsi({
                appointmentNumber: id,
                user,
                authToken, 
                roomUrl: roomAttr.joinUrl,
                isApp,
            });
    
            this.setState({
                started:  true, 
                isJoinSession: true,
                postJoinData:  postData
            });

            const ini = this;

            this.chatContainer.onConnected()
                .then((jitsiRoom) => {
                    const { JitsiMeetJS } = window;

                    const { user } = this.props;

                    this.setState({ isTextAreaEnabled: true });

                    jitsiRoom.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
                        const { postJoinData } = ini.state;
                        this.chatRoomPublisher(postJoinData, false);
                    });
                    
                    jitsiRoom.on(JitsiMeetJS.events.conference.MESSAGE_RECEIVED, (id, text, ts) => {
                        const newId = _uniqueId(user.getAppointmentNumber() || '');
                        const message = JSON.parse(text);
                        if (!message.id) {
                            message.id = newId;
                        }
                        
                        if (message.format !== 'endingSession') {
                            const { messageList } = ini.state;
                            if (message.format === 'session_join') {
                                if (!messageList.find(e => e.format === 'session_join' && e.sender === 'practitioner')) {
                                    messageList.push(message);
                                }

                                if (!messageList.find(e => e.format === 'session_join' && e.sender === 'patient')) {
                                    messageList.push(message);
                                }
                            }else {
                                messageList.push(message);
                            }
                            this.setState({ messageList });   
                            this.scrollToBottom();
                        }else {
                            const { location: { search } } = this.props;
                            const params = getParams(search);
                            const { domain } = params;
                            this.setState({ isEndConversation: true, done: true });
                            sendMessage({
                                type: 'paymentSuccess',
                                data: message.payment
                            }, domain);

                            const endByPractitionerDetail= { 
                                sender: "practitioner", 
                                time: new Date().toISOString(), 
                                format: 'session_end',
                                text: 'end chat',
                                id: newId,
                                created_date: new Date().toISOString(), 
                                appointment_number: user.getAppointmentNumber()
                            }

                            this.chatRoomPublisher(endByPractitionerDetail, false);
                        }  
                    });
                    
                });
        }
    }

    handleChange(event) {
        if (event.nativeEvent.inputType !== 'insertLineBreak') {
            this.setState({ message: event.target.value, isTyping: true });
        }
    }

    handlePressKey(event) { 
        if (event.key === 'Enter') {
            this.sendMessage();
        }
    }

    sync() {
        const { messageList } = this.state;
        this.props.onSync(messageList);
    }

    chatRoomPublisher = (data, isSave = true) => {
        if (this.chatContainer)  {
            const jitsiRoom = this.chatContainer.getRoom();
            jitsiRoom.sendTextMessage(JSON.stringify(data));
        }

        if (isSave) {
            const newId = _uniqueId(data.appointmentId || '');
            data.id = newId;
    
            this.props.onSaveChat(data);
        }
    }

    reqVideoCall = async () => {
        const { user, keycode, roomAttr: { joinUrl }, doctorWeb, location: { search } } = this.props;
        // let message = this.state.message.replace(/ +(?= )/g, '');
        const params = getParams(search);
        const { domain, country, lang, facilityId, uid } = params;

        const data = {
            text: user.getNickname(),
            format: 'video_jitsi',
            sender: user.getType(),
            appointmentId: user.getAppointmentNumber(),
            nickname: user.getNickname(),
            time: new Date().toISOString(),
            isLive: true
        };

        this.chatRoomPublisher(data);

        const dataJitsi = {
            domain,
            country,
            lang,
            facilityId,
            doctorId: uid
        }

        const isPractitioner = doctorWeb;
        const jitsiUrl = getJistsiURL(data, isPractitioner, dataJitsi);
        const jitsiInAppURL = getJistsiInAppURL(data, joinUrl);
        const isForceToJitsiUrl = keycode && (/mednet/i).test(keycode);
        const fromMobile = isInAppIframe(keycode)

        if (!isForceToJitsiUrl && fromMobile) {
          const postData = {
              type: 'VIDEO_URL',
              displayName: user.getNickname(),
              avatar: user.getAvatar(),
              fromMobile,
              meetUrl: jitsiInAppURL
          }

          sendMessage({
              ...postData
          }, false, true);
        } else {
            window.open(jitsiUrl, "_blank");
        }
    }

    sendAsFile = async (message) => {
        let { file, fileContent, messageList } = this.state;
        const { authToken, appointment, user } =  this.props;

        if (message) {
            messageList = messageList.filter(m => m.type !== message.type);
            this.setState({
                messageList
            });
        }
        const data = {
            type: `temporary-${user.getJitsyID()}`,
            text: fileContent,
            filetype: file.type,
            format: 'loading',
            sender: user.getType(),
            appointmentId: user.getAppointmentNumber(),
            nickname: user.getNickname(),
            time: new Date().toISOString(),
            connectionId: user.getJitsyID()
        };
        messageList.push(data);
        this.setState({
            messageList
        });

        this.scrollToBottom();
        const [, resp] = await to(onUploadImage(appointment.id, fileContent, authToken));
        if (resp) {
            messageList = messageList.filter(m => m.type !== `temporary-${user.getJitsyID()}`);
            this.setState({
                messageList
            });
            const data = {
                text: resp.data.data.url,
                format: 'image',
                sender: user.getType(),
                appointmentId: user.getAppointmentNumber(),
                nickname: user.getNickname(),
                time: new Date().toISOString(),
                connectionId: user.getJitsyID()
            };
            this.chatRoomPublisher(data);
            this.clearFile();
        }else {
            messageList = messageList.map(m => {
                if (m.type === `temporary-${user.getJitsyID()}`) {
                    m.retry = true;
                    return m;
                }
                return m;
            });
            this.setState({
                messageList
            });
        }
        
    }

    sendAsText = (msg) => {
        const { user } = this.props;
        let message = this.state.message.replace(/ +(?= )/g, '');
        if (message.trim() !== '') {
            const data = {
                text: message,
                format: 'text',
                sender: user.getType(),
                appointmentId: user.getAppointmentNumber(),
                nickname: user.getNickname(),
                time: new Date().toISOString()
            };

            // push to jitsiRoom
            this.chatRoomPublisher(data);
        }
    }

    sendMessage = async(message) =>{
        const { file } = this.state;
        const { user } =  this.props;
        
        if (user && !_isEmpty(file.name)) {
            this.sendAsFile(message);
        }

        if (user && _isEmpty(file.name)) {
            this.sendAsText(message);   
        }

        this.setState({ message: '', isTyping: false });
    }

    scrollToBottom() {
        setTimeout(() => {
            try {
                this.chatScroll.current.scrollTop = this.chatScroll.current.scrollHeight;
            } catch (err) {
            }
        }, 20);
    }

    close() {
        this.props.close(undefined);
    }

    showAttachmentDialog() {
        this.setState({
            showAttachmentDialog: true
        });
    }

    setFile = (value) => {
        this.setState({ file: value });
    }

    setFileContent(value) {
        this.setState({ fileContent: value });
    }

    clearFile() {
        this.setState({ file: {} });
    }
     // to combat disabled autoplay on browser for security reaseon
     playAllVideo(e) {
        const v = document.getElementsByClassName("stream-component__video")
        for(let i = 0; i < v.length; i++) {
            const el = v.item(i);
            if (el) {
                try {
                    el.play();
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }

    cancel() {
        if(this.request.length > 0) {
            for (let index = 0; index < this.request.length; index++) {
                const element = this.request[index];
                element.call();
            }
        }

        if (this.saveChatHistoryRequest) {
            this.saveChatHistoryRequest.call();
        }

        clearInterval(this.checking);
        mouse.forEach(e => {
            window.removeEventListener(e, this.playAllVideo);
        });

        keyboard.forEach(e => {
            window.removeEventListener(e, this.playAllVideo);
        });

        touch.forEach(e => {
            window.removeEventListener(e, this.playAllVideo);
        });
    }

    checkStatus(){
        const { 
            location: { search }, appointment
        } = this.props;
        const params = getParams(search);
        const { domain } = params;
        const isStarted = appointment ? dayUtc(appointment.start).local().unix() >= dayUtc().local().unix() : false;

        if (isStarted) {
            sendMessage({
                type: 'appointmentStarted'
            }, domain);
        }
    }
   

    render() {
        const { 
            appointment, user, loadingChatHistory, rated,
            openPhoto, doctorWeb, t, themes, onErrorAttachment, notStartedYet, keycode, location: { search }, roomAttr
        } = this.props;
        const params = getParams(search);
        const { domain, id, country, lang, facilityId, uid } = params;

        const { 
            message, messageList, uploading, loading, done, file, 
            fileContent, started, isJoinSession, isTyping, isTextAreaEnabled, isEndConversation
        } = this.state;

        const filteredMessage = [];
        messageList.map((value) => {
            if (value.format === 'session_end') {
                const exising = filteredMessage.find( e => e.format === value.format);
                if (!exising) {
                    filteredMessage.push(value);    
                }
            }else {
                filteredMessage.push(value);
            }
            return value;
        })

        const allMsg = filteredMessage.sort((a, b) => {
            if(a.timestamp === b.timestamp) {
                return 0;
            }
            return a.timestamp - b.timestamp;
        }); 

        const isPast = theDate(appointment.endTime).local().unix() < theDate().local().unix();
        const aptStatusDone = ['done', 'no show', 'cancelled', 'payment expired'];
        const disabledFilePicker = aptStatusDone.indexOf((_get(appointment, 'status') || '').toLowerCase()) >= 0;

        return (
            <Fragment>
                <Header 
                    t={t}
                    started={started}
                    appointment={appointment}
                    done={done}
                    user={user}
                    themes={themes}
                    sessionClosed={!isJoinSession}
                    doctorWeb={doctorWeb}
                    notStartedYet={notStartedYet}
                    practitioner={appointment.doctor}
                    patient={appointment.patient}
                    messages={allMsg}
                    onEndSession={() => {
                        if (!done) {
                            this.cancel();
                            sendMessage({
                                'type': 'endSession',
                                'session_id': id
                            }, domain);
                        }
                    }}
                    onBackButtonClick={() => {
                        this.cancel();
                        sendMessage({
                            'type': 'backSession'
                        }, domain);
                    }}
                    onVideoCallClick={async() => {
                        this.reqVideoCall();
                    }}
                    onLeave={async () => {
                        // if (getVideoCallProvider() !== "jitsi") {
                        //     this.stopVideo();
                        // }
                    }}
                />
                <div className="chat">
                    <div className="chat__log" ref={this.chatScroll}>
                        <div className={`container ${allMsg.length > 0 ? 'mt-auto' : ''}`}>
                            <NotStartedYet 
                                t={t} 
                                appointment={appointment}
                                doctorWeb={doctorWeb}
                                started={started}
                                isPast={isPast}
                            />
                            {loadingChatHistory && (
                                <ConsultationLoading t={t} />
                            )}
                            {(started || done)&& 
                                <Fragment>
                                    { 
                                        user.getType() === 'patient' && <ConsultationPrivacy t={t} />
                                    }
                                    
                                    {
                                        allMsg.map((data, i) => (
                                            <MessageComponent
                                                themes={themes}
                                                isPatient={!doctorWeb}
                                                key={i}
                                                t={t}
                                                lang={lang}
                                                domain={domain}
                                                facilityId={facilityId}
                                                doctorId={uid}
                                                isTyping={isTyping}
                                                keycode={keycode}
                                                data={data}
                                                country={country}
                                                user={user}
                                                openPhoto={openPhoto}
                                                onVideoCallClick={this.reqVideoCall}
                                                onRetryClick={(m) => this.sendMessage(m)}
                                                roomAttr={roomAttr}
                                            />
                                        ))
                                    }
                                </Fragment>
                            }
                        </div>
                    </div>
                    <div className="chat__input">
                        <div className="container">
                            {(appointment.status || '').toLowerCase() === 'done' && !appointment.fg_feedback_filled && done && !loading && !rated && !doctorWeb && (
                                <div className="chat__input-rate">
                                     <ButtonGradient
                                        themes={themes}
                                        className="green"
                                        onClick={() => {
                                            this.props.onRateButtonClick();
                                        }}
                                        >
                                        {t('Rate your experience')}
                                    </ButtonGradient>
                                </div>
                            )}

                            {!done && (!started || !isEndConversation) && (
                                <div className="chat__input-group">
                                    <div className="chat__input-attachment">
                                        <FilePicker
                                            className="chat__input-attachment-button"
                                            maxSize={10}
                                            extensions='jpeg, png, pdf, jpg'
                                            accept={["image/jpeg", "image/png", "application/pdf"]}
                                            onError={(e) => {
                                                onErrorAttachment(e, e);
                                            }}
                                            onChange={(file) => {
                                                var reader = new FileReader();
                                                reader.onload = (e) => {
                                                    this.setFileContent(e.target.result);
                                                };

                                                reader.readAsDataURL(file);
                                                this.setFile(file);
                                            }}
                                            onClear={this.clearFile}
                                            disabled={disabledFilePicker || !started}
                                        >
                                            <button 
                                                className="button"
                                                disabled={disabledFilePicker || !started}
                                                >
                                                <Png
                                                    name="ic-attachment"
                                                    className="img-responsive"
                                                    alt="attachment"
                                                    width="10px"
                                                    />
                                            </button>
                                        </FilePicker>
                                    </div>
                                    <div className="chat__input-box-wrapper">
                                        {fileContent && !_isEmpty(file.name) && (
                                            <div className="chat-attachment">
                                                <div className="chat-attachment__preview">
                                                    <div className="chat-attachment__preview-info">
                                                        <div className="thumbnail thumbnail--square">
                                                            <Png
                                                                name={file.type === 'application/pdf' ? 'ic-file' : fileContent}
                                                                className="thumbnail__image"
                                                                alt="uploaded"
                                                                external={file.type !== 'application/pdf'}
                                                                />
                                                        </div>
                                                        <p className="text-gray text-truncate">{file.name}</p>
                                                    </div>
                                                    <button 
                                                        className="button chat-attachment__preview-clear"
                                                        onClick={(e) => {
                                                            let { messageList } = this.state;
                                                            messageList = messageList.filter(m => m.type !== `temporary-${this.props.user.getJitsyID()}`);
                                                            this.setState({
                                                                messageList
                                                            });                                                        
                                                            this.clearFile(e);
                                                        }}
                                                        >
                                                        <Png
                                                            name="ic-close"
                                                            className="img-responsive"
                                                            alt="close"
                                                            width="10px"
                                                            />
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        {_isEmpty(file.name) && (
                                            <textarea
                                                autoComplete="off"
                                                rows="1"
                                                disabled={isEndConversation || !started || !isTextAreaEnabled}
                                                value={this.state.message}
                                                onChange={(e) => this.handleChange(e)}
                                                onKeyPress={(e) => {
                                                    this.handlePressKey(e);
                                                    
                                                    return false;
                                                }}
                                                id="chatInput" 
                                                type="text" 
                                                className="chat__input-box" 
                                                placeholder={t('Type a message')}/>
                                        )}
                                    </div>
                                    <button 
                                        disabled={isEndConversation || uploading}
                                        className={`chat__input-send ${(message !== '' || !_isEmpty(file.name)) ? 'chat__input-send--active' : ''}`}
                                        onClick={() => {
                                            this.sendMessage();
                                        }}>
                                        <Png
                                            name="ic-send"
                                            className="img-responsive"
                                            alt="send message"
                                            width="35px"
                                            />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }


}

const BackToVideo = ({ counter , setMinimizeVideo, t}) => {
    return (
        <div 
            className="video-call-alert" 
            onClick={() => setMinimizeVideo()}>
            <div className="container">
                <div className="video-call-alert__info">
                    <div className="video-call-alert__info-text">
                        <p className="text-white">{t('Tap to return to video call')}</p>
                    </div>
                    <div className="video-call-alert__info-time">
                        <p className="text-white">{counter}</p>
                        </div>
                </div>
            </div>
        </div>
    )
}


export default {
    // Header: Header,
    Box: ChatComponent,
    Photo: Photo,
    Video: Video,
    BackToVideo: BackToVideo
}
