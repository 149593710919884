import { log } from "./domain";

const sendMessage = (message, domain, isWebview) => {
    if (typeof message === 'object') {
        message = JSON.stringify(message);
    }

    domain = domain ? `${window.location.protocol}//${domain}` : "*";
    let sent = [];
    if (typeof window !== 'undefined' && typeof window.parent !== 'undefined') {
        sent.push('iframe');
        window.parent.postMessage(message, domain);
    }

    if (isWebview && typeof window !== 'undefined' && typeof window.ReactNativeWebView !== 'undefined') {
        sent.push('webview');
        window.ReactNativeWebView.postMessage(message);
    }

    if (isWebview && 
        typeof window !== 'undefined' &&
        typeof window.webkit !== 'undefined' &&
        typeof window.webkit.messageHandlers !== 'undefined' &&
        typeof window.webkit.messageHandlers.cordova_iab !== 'undefined') {
        sent.push('webview');
        window.webkit.messageHandlers.cordova_iab.postMessage(message);
    }
    log('sent', sent);
}

export {
    sendMessage
}

