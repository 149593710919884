import React, { useRef } from 'react';
import FileInput from './FileInput';

// for validating input file attribut/property
// acceptExtList => currently only support `(.png,.doc,.pdf,.jpg)` format
// instead of using `image/png or image/* or application/msword .etc` format
const isValidExtension = (fileName, acceptExtList = false) => {
    if (!acceptExtList) return true;
    fileName = typeof fileName === 'string' ? fileName.toLowerCase() : fileName;
    const listExt = acceptExtList.replace(/\s/g, '').replace(/,/g, '|');
    const regexFormat = `^.*.(${listExt})$`;
    const regExp = new RegExp(regexFormat);
    return regExp.test(fileName);
};

// validates size of file
// fileSize (in Byte)
// maxSize (in MB)
export const isValidSize = (fileSize = 0, maxSize = 0) => {
    let isValid = true;
    if (fileSize > 0 && maxSize > 0) {
        const sizeInMB = fileSize / 1024 / 1024;
        isValid = sizeInMB <= maxSize;
    }
    return isValid;
};

export default (props) => {
    const {
        className,
        children,
        onError,
        onChange,
        maxSize,
        extensions,
        ...rest
    } = props;

    const inputFileRef = useRef(null);

    const validates = (file) => {
        // make sure a file was provided in the first place
        if (!file) {
            onError('Failed to load file.');
            return;
        }

        const { name, size } = file;

        if (Array.isArray(extensions) && extensions.length > 0) {
            const isValidFileExt = isValidExtension(name, extensions);

            if (!isValidFileExt) {
                onError(`Must upload a file of type: ${extensions.join(', ')}`);
                return;
            }
        }

        const isValidFileSize = isValidSize(size, maxSize);

        if (!isValidFileSize) {
            onError(`File size must be less than ${maxSize} MB.`);
            return;
        }

        if (typeof onChange === 'function') {
            onChange(file);
        }
    };

    const onClickBrowse = () => {
        if (!rest.disabled) {
            inputFileRef.current.browseFile();
        }
    };

    return (
        <div onClick={onClickBrowse} className={className}>
            <FileInput 
                {...rest}
                onChange={validates} 
                ref={inputFileRef}
            >
                {children}
            </FileInput>
        </div>
    );
};
