import to from 'await-to-js';
import _get from 'lodash/get';

import { 
    saveChatHistory, getAppointmentDetail, getBlockContent,  getInit, getDetailedRoom
} from '../../libs/api';

import UserModel from '../../libs/user';

const getUser = ({ userID, appointmentNumber, type, nickname, ...props }) => {
    const user = new UserModel();
    user.setNickname(nickname || `public-${Math.round(Math.random() * 100)}`);
    user.setAppointmentNumber(appointmentNumber);
    user.setID(userID);
    user.setType(type);
    user.setAvatar(props.avatar);
    return user;
}

export const initUser = (type = 'doctor', appointment) => {
    const t = type === 'doctor' ? constanta.PRACTITIONER : constanta.PATIENT;
    const nickname = type === 'doctor' ? appointment[type].title : [appointment[type].first_name, appointment[type].last_name].join(' ')
    const user = getUser({
        userID: appointment[type].id, 
        appointmentNumber: appointment.id,
        type: t, 
        nickname,
        avatar: appointment[type].profilePicture
    });

    return user;

}

export const constanta = {
    PRACTITIONER: 'practitioner',
    PATIENT: 'patient'
};

export const getDetailAppointment = (appointmentNumber, token, isDoctor, options) => new Promise( async(resolve, reject) => {
    const [err, resp] = await to(getAppointmentDetail(appointmentNumber, token, isDoctor ? constanta.PRACTITIONER : constanta.PATIENT, options));
    if (err) {
        reject(err);
    }
    resolve(resp);
});

export const getDetailTelemedicine = (appointmentNumber, token) => new Promise( async(resolve, reject) => {
    const [err, resp] = await to(getDetailedRoom(appointmentNumber, token));
    if (err) {
        reject(err);
    }

    resolve(resp);
})

const convertArrayToObject = (array, key) => 
  array.reduce((acc, curr) => {
    acc[curr[key]] = curr;
    return acc;
  }, {})

export const getBrowserNotSupportBC = async({ token, whitelabelCode = '' }) => new Promise( async(resolve, reject) =>  {
    const keyMobile = `browser-notsupported-mobile`;
    const keyMobileWL = `${keyMobile}-${whitelabelCode}`;
    const keyDesktop = `browser-notsupported-desktop-telemedicine`;
    const keyDesktopWL = `${keyDesktop}-${whitelabelCode}`;

    let keys = `${keyMobile},${keyDesktop}`;

    if (whitelabelCode) {
        keys = `${keys},${keyMobileWL},${keyDesktopWL}`;
    }

    const [err, resp] = await to(getBlockContent(keys, token, {}));

    let blockRes = { mobile: null, desktop: null };

    if (err) {
        resolve(blockRes);
    }

    const res = _get(resp, 'data.data') || [];

    if (Array.isArray(res) && res.length  > 0) {
        const blocks = convertArrayToObject(res, 'key');
        blockRes.mobile = blocks[keyMobile].value;
        blockRes.desktop = blocks[keyDesktop].value;

        if (whitelabelCode) {
            blockRes.mobile = blocks[keyMobileWL].value ? blocks[keyMobileWL].value : blockRes.mobile;
            blockRes.desktop = blocks[keyDesktopWL].value ? blocks[keyDesktopWL].value : blockRes.desktop;
        }
    }
    resolve(blockRes);
});

export const saveChat = async (appointmentNumber, message, token, options) => new Promise(async(resolve, reject) => {
    const [err, resp] = await to (saveChatHistory(appointmentNumber, message, token, options));
    if(err) {
        reject(err);
    }
    resolve(resp);
}); 

export const getAppInit = async(clientID, country,  lang) => new Promise(async(resolve, reject) => {
    const options = { 
        headers: {
            'Client-ID': clientID || process.env.REACT_APP_CLIENT_ID,
            'Accept-Language': lang,
            'Country-ID': country,
        }
    };

    const [err, resp] = await to(getInit(options));
    if (err) {
        reject(err);
    }
    resolve(resp);
});