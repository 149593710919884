import React, { Fragment, useState } from 'react';
import Png from "../../components/Png";
import { sendMessage } from '../../libs/message';
import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ButtonGradient, LinkButtonGradient } from '../../libs/themes';
import { getJistsiInAppURL, getJistsiURL, isInAppIframe } from '../../libs/jitsi/url';

let theDate = day.extend(utc);
theDate = theDate.extend(advancedFormat);
theDate = theDate.extend(localizedFormat);
theDate = theDate.extend(relativeTime);

const VideoJitsy = ({ data, user, userEmail, t, country, lang, facilityId, doctorId, themes, domain, ...rest }) => {
    const { isPatient, keycode, roomAttr: { joinUrl } } = rest;

    const [isOpen, setOpen ] = useState(true);

    const jitsiUrl = getJistsiURL(data, !isPatient, { domain, country, lang, facilityId, doctorId })
    const jitsiInAppURL = getJistsiInAppURL(data, joinUrl)
   
    const [jitsiData, ] = useState({
        type: 'VIDEO_URL',
        displayName: user.getNickname(),
        email: userEmail,
        avatar: user.getAvatar()
    })

    const isInApp = isInAppIframe(keycode);
    const isForceToJitsiUrl = keycode && (/mednet/i).test(keycode);
    const messageDate = theDate(data.time).local();
    const isToday = messageDate.format('YYYY-MM-DD') === theDate().local().format('YYYY-MM-DD');
    const messageTimeFormat = isToday ? 'HH:mm' : 'Do, MMM YYYY HH:mm'
    
    let leftChat = false;
    if ((isPatient && data.sender === 'patient') || (!isPatient && data.sender !== 'patient')) {
        leftChat = true;
    }

    const openVideoConference = e => {
        e.preventDefault();
        if (!isForceToJitsiUrl && isInApp) {
            sendMessage({
                ...jitsiData,
                meetUrl: jitsiInAppURL
            }, false, true);
        } else {
            window.open(jitsiUrl, "_blank", "noopener,noreferrer");
        }
    }

    if (!leftChat) {    
        return (
            <div className={
                'chat-message ' + (leftChat ? 'current-user' : '')
                }>
                <div className="chat-message__content">
                    <div className="chat-message__content-call">
                        <div className="chat-message__content-call--icon">
                            <Png
                                name="ic-phone"
                                className={`img-responsive ${leftChat? 'img-white' : ''}`}
                                alt="call"
                                width="20px"
                            />
                        </div>
                        <p>{t(`${data.sender} started a video call`)}</p>
                    </div>
                    {!data.from_me &&
                        <div className="d-flex justify-content-end">
                            {isInApp && (
                                 <LinkButtonGradient
                                    themes={themes}
                                    className="green"
                                    onClick={openVideoConference}
                                >
                                    {t('Join the call')}
                                </LinkButtonGradient>
                            )}
                            {!isInApp && (
                            <ButtonGradient
                                themes={themes}
                                className="green"
                                type="button" 
                                // disabled={disabled}
                                onClick={() => {
                                    // onVideoCallClick();
                                    window.open(jitsiUrl, "_blank");
                                }}>
                                {t('Join the call')}
                            </ButtonGradient>
                            )}
                        </div>
                    }
                    <p className="chat-message__content-time">
                        <small>{messageDate.format(messageTimeFormat)}</small>
                    </p>
                </div>
            </div>
        )
    }

    
    return (
        <div className="alert">
            <p>
                <small>
                    {!isInApp && (
                        <a target="_blank" className="button text-blue" style={{ textDecoration: 'underline' }} rel="noopener noreferrer" href={jitsiUrl} >{t('Please click this link to join video call')}</a>
                    )}
                    {isInApp && (
                        <LinkButtonGradient
                            themes={themes}
                            className="green"
                            onClick={openVideoConference}
                        >
                            {t('Please click this link to join video call')}
                        </LinkButtonGradient>
                    )}
                </small>
            </p>
            <p className="chat-message__content-time">
                <small>{messageDate.format(messageTimeFormat)}</small>        
            </p>
        </div>
    )
}

const TextMessage = ({ isPatient, data }) => {
    let leftChat = false;
    if ((isPatient && data.sender === 'patient') || (!isPatient && data.sender !== 'patient')) {
        leftChat = true;
    }
    const messageDate = theDate(data.time).local();
    const isToday = messageDate.format('YYYY-MM-DD') === theDate().local().format('YYYY-MM-DD');
    const messageTimeFormat = isToday ? 'HH:mm' : 'Do, MMM YYYY HH:mm'
    return (
        <div className={
            'chat-message ' + (leftChat ? 'current-user' : '')
            }>
            <div className="chat-message__content">
                <Fragment>
                    <p className="chat-message__content-text">{data.text}</p>
                    <p className="chat-message__content-time">
                        <small>{messageDate.format(messageTimeFormat)}</small>
                    </p>
                </Fragment>
            </div>
        </div>
    )
};

const ImageMessage = ({ isPatient, data, openPhoto }) => {
    let leftChat = false;
    if ((isPatient && data.sender === 'patient') || (!isPatient && data.sender !== 'patient')) {
        leftChat = true;
    }
    const messageDate = theDate(data.time).local();
    const isToday = messageDate.format('YYYY-MM-DD') === theDate().local().format('YYYY-MM-DD');
    const messageTimeFormat = isToday ? 'HH:mm' : 'Do, MMM YYYY HH:mm';

    if (!data.filetype) {
        data.filetype = data.text.indexOf('.pdf') > -1 ? 'application/pdf' : 'image';
    }

    const Image = ({ photo, downloadable }) => (
        <div className="thumbnail thumbnail--square">
            <Png
                name={downloadable ? 'ic-file' : photo}
                external={!downloadable}
                className="thumbnail__image"
                alt="attachment"
            />
        </div>
    )

    return (
        <div className={
            'chat-message ' + (leftChat ? 'current-user' : '')
            }>
            <div className="chat-message__content">
                {data.filetype === 'application/pdf' && (
                    <a target="_blank" rel="noopener noreferrer" href={data.text} className="chat-message__image">
                        <Image downloadable />
                    </a>
                )}
                {data.filetype !== 'application/pdf' && (
                    <div className="chat-message__image" onClick={() => openPhoto(data)}>
                        <Image photo={data.text} />
                    </div>
                )}
                <p className="chat-message__content-time">
                    <small>{messageDate.format(messageTimeFormat)}</small>
                </p>
            </div>
        </div>
    )
}

const ImageMessageLoading = ({ isPatient, data, onRetryClick, t }) => {
    let leftChat = false;
    if ((isPatient && data.sender === 'patient') || (!isPatient && data.sender !== 'patient')) {
        leftChat = true;
    }
    if (!data.filetype) {
        data.filetype = data.text.indexOf('.pdf') > -1 ? 'application/pdf' : 'image'
    }
    return (
        <div className={
            'chat-message ' + (leftChat ? 'current-user' : '')
            }>
            <div className="chat-message__content">
                <div className="chat-message__image">
                    <div className="thumbnail thumbnail--square blur">
                        <Png
                            name={data.filetype === 'application/pdf' ? 'ic-file' : data.text}
                            external={data.filetype !== 'application/pdf'}
                            className="thumbnail__image"
                            alt="attachment"
                        />
                        {data.retry && (
                            <button onClick={() => onRetryClick(data)} className="button retry-button">{t('Retry')}</button>
                        )}
                    </div>
                    {!data.retry && (
                        <div className="spin ok">
                            <div className="spin__item" role="status" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const SessionEndEventMessage = ({ data, t }) => {
    const messageDate = theDate(data.time).local();
    const isToday = messageDate.format('YYYY-MM-DD') === theDate().local().format('YYYY-MM-DD');
    const messageTimeFormat = isToday ? 'HH:mm' : 'Do, MMM YYYY HH:mm'
    return (
        <div className="alert alert--success alert--full rounded-0">
            <p>
                <small>{t('Consultation ended by {{sender}} at ', { sender: data.sender })}<strong>{messageDate.format(messageTimeFormat)}</strong></small>
            </p>
        </div>
    )
}


const SessionJoinEventMessage = ({ data, t }) => {
    const messageDate = theDate(data.time).local();
    const isToday = messageDate.format('YYYY-MM-DD') === theDate().local().format('YYYY-MM-DD');
    const messageTimeFormat = isToday ? 'HH:mm' : 'Do, MMM YYYY HH:mm'
    return (
        <div className="alert">
            <p>
                <small>{t('{{sender}} joined a room', { sender: data.text })}</small>
            </p>
            <p className="chat-message__content-time">
                <small>{messageDate.format(messageTimeFormat)}</small>        
            </p>
        </div>
    )
}

const VideoEndMessage = ({ data, t }) => {
    const messageDate = theDate(data.time).local();
    const isToday = messageDate.format('YYYY-MM-DD') === theDate().local().format('YYYY-MM-DD');
    const messageTimeFormat = isToday ? 'HH:mm' : 'Do, MMM YYYY HH:mm'
    return (
        <div className="alert">
            <p>
                <small>{t('Video call ended')}</small>
            </p>
            <p className="chat-message__content-time">
                <small>{messageDate.format(messageTimeFormat)}</small>        
            </p>
        </div>
    )
}

const VideoStartMessage = ({ patient, data, onVideoCallClick, t, disabled, themes}) => {
    const leftChat = patient;
    const messageDate = theDate(data.time).local();
    const isToday = messageDate.format('YYYY-MM-DD') === theDate().local().format('YYYY-MM-DD');
    const messageTimeFormat = isToday ? 'HH:mm' : 'Do, MMM YYYY HH:mm'
    return (
        <div className={
            'chat-message ' + (leftChat ? 'current-user' : '')
            }>
            <div className="chat-message__content">
                <div className="chat-message__content-call">
                    <div className="chat-message__content-call--icon">
                        <Png
                            name="ic-phone"
                            className={`img-responsive ${leftChat? 'img-white' : ''}`}
                            alt="call"
                            width="20px"
                        />
                    </div>
                    <p>{t(`{{sender}} started a video call`, { sender: data.from_me ? 'You' : data.text })}</p>
                </div>
                {!data.from_me &&
                    <div className="d-flex justify-content-end">
                        <ButtonGradient
                            themes={themes}
                            className="green"
                            type="button" 
                            disabled={disabled}
                            onClick={() => {
                                onVideoCallClick();
                            }}>
                            {t('Join the call')}
                        </ButtonGradient>
                    </div>
                }
                <p className="chat-message__content-time">
                    <small>{messageDate.format(messageTimeFormat)}</small>
                </p>
            </div>
        </div>
    )
}

const ConsultationPrivacy = ({ t }) => (
    <div className="alert alert--success">
        <p>
            <small>{t('Your conversation with our practitioners is safe and cannot be viewed by other user')}</small>
        </p>
    </div>
);

const ConsultationLoading = ({ t }) => (
    <div className="alert alert--info">
        <p>
            <small>{t('loading messages.')}</small>
        </p>
    </div>
);

class MessageComponent extends React.Component {

    // prevent re-render when user isTyping
    shouldComponentUpdate(nextProps, nextState) { 
        if (!nextProps.isTyping) { 
          return true;
        }
        return false;
    }

      
    render(){
        const { data, isPatient } = this.props;
        return (
            <Fragment>
                {data.format === 'session_end' && (
                    <SessionEndEventMessage {...this.props} />
                )}
                {data.format === 'session_join' && (isPatient && data.sender === 'practitioner') && (
                    <SessionJoinEventMessage {...this.props} />
                )}
                {data.format === 'session_join' && (!isPatient && data.sender === 'patient') && (
                    <SessionJoinEventMessage {...this.props} />
                )}
                {data.format === 'video_jitsi' && (
                    <VideoJitsy {...this.props} />
                )}
                {data.format === 'video_call_start' && (
                    <VideoStartMessage {...this.props} />
                )}
                {data.format === 'video_call_end' && (
                    <VideoEndMessage {...this.props} />
                )}
                {data.format === 'text' && (
                    <TextMessage {...this.props} />
                )}
                {data.format === 'image' && (
                    <ImageMessage {...this.props} />
                )}
                {data.format === 'loading' && (
                    <ImageMessageLoading {...this.props} />
                )}
            </Fragment>
        );
    }
}

export {
    ConsultationPrivacy,
    ConsultationLoading
}
export default MessageComponent;
