import _get from 'lodash/get';

const getThemes = (whitelabel) => {
    const themes = _get(whitelabel, 'colour_config') || false;
    if (!themes) {
        return {
            bg_filter: '#42a1ec',
            bg_landing: '#ffffff',
            bg_profile: '#f7f7f7',
            bg_header: '#fff',
            button_cta: '#41d6c3',
            text_button_cta: '#ffffff',
            text_main: '#42a1ec'
        };
    }

    return themes;
};

export default getThemes;