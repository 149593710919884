import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Chatbox from './containers/ChatBox';
import { withTranslation } from 'react-i18next';
import { load } from './i18n';
import { init as initApm } from '@elastic/apm-rum';
import { addKonami, log, logs } from './libs/domain';

initApm({
    serviceName: process.env.REACT_APP_TRACKING_SERVICE_NAME,
    serverUrl: process.env.REACT_APP_TRACKING_APM_SERVER_URL,
    environment: process.env.REACT_APP_TRACKING_ENVIRONMENT,
});

function App(props) {
    const onStart = (token, lang, country) => {
        load(token, lang, country);
    }
    const [debug, setDebug] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            addKonami(() => {
                setDebug(true);
            });
            setLoaded(true);
        }
        return () => {
            log('cleanup');
        }
    }, [debug, loaded]);
    return (
        <Router>
        <Switch>
            <Route exact path="/">
                <Chatbox {...props} onStart={onStart} />
                {debug && (
                    <div className={`debug`}>
                        <div onClick={() => {
                            setDebug(false);
                        }}>close</div>
                        <ul id="debug">
                            {logs.map((b, i) => (
                                <li key={i}>{JSON.stringify(b)}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </Route>
        </Switch>
        </Router>
    );
}

export default withTranslation('common')(App);
