import Service from './service';

export default class Account extends Service {

    constructor(options = {}) {
        options.name = 'account';
        // default base url for account api
        options.baseURL = process.env.REACT_APP_ACCOUNT_API_URL;
        super(options);
    }

    // add others methods here...
}