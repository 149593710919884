
import React, { Fragment } from 'react';
import Png from "./Png";

import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import _get from 'lodash/get';

const priorTime = process.env.REACT_APP_TELEMEDICINE_PRIOR_TIME || 600; // default is 10 minutes;
const priorInHour = (+priorTime / 3600);
const priorInMinute = (+priorTime / 60);
const isHour = priorInHour >= 1;

let theDate = day.extend(utc);
theDate = theDate.extend(advancedFormat);
theDate = theDate.extend(localizedFormat);
theDate = theDate.extend(relativeTime);

const second_in_minute = 60;
const second_in_hour = 60 * second_in_minute;
const second_in_day = 24 * second_in_hour;

const getRemainingTime = (to) => {
    const now = theDate().local();
    const diffc = to.unix() - now.unix();
    let seconds = diffc;
    let day = 0;
    let hours = 0;
    let minutes = 0;
    if (diffc > second_in_day) {
        day = Math.floor(seconds / second_in_day);
        seconds = seconds % (day * second_in_day);
    }
    if (seconds > second_in_hour) {
        hours = Math.floor(seconds / second_in_hour);
        seconds = seconds % (hours * second_in_hour);
    }
    if (seconds > second_in_minute) {
        minutes = Math.floor(seconds / second_in_minute);
        seconds = seconds % (minutes * second_in_minute);
    }
    const r =  {
        day: day,
        hour: hours,
        minute: minutes,
        second: seconds,
        seconds: diffc
    };
    return r;
}

const RemainingTime = ({ remainingTime, t }) => (
    <Fragment>
        <span className="text-bold">{remainingTime.day}&nbsp;</span><span>{remainingTime.day > 1 ? t(`Days`) : t(`Day`)},&nbsp;</span>
        <span className="text-bold">{remainingTime.hour}&nbsp;</span><span>{remainingTime.hour > 1 ? t(`Hours`) : t(`Hour`)},&nbsp;</span>
        <span className="text-bold">{remainingTime.minute}&nbsp;</span><span>{remainingTime.minute > 1 ? t(`Minutes`) : t(`Minute`)}</span>
    </Fragment>
);

const NotStartedYet = ({ t, started, isPast, doctorWeb, appointment }) => {
    const status = _get(appointment, 'appointment_status') || '';
    const showRemaining = (status.toLowerCase() !== 'done' && !started && !isPast);

    if (!showRemaining) {
        return null;
    }

    const remainingTime = getRemainingTime(theDate(appointment.start).local());
    return (
        <div className="chat__log--not-started">
            <div className="chat__log-notice">
                <Png
                    name="ic-specialist"
                    className="img-responsive"
                    alt="appointment not ready"
                    width="150"
                />
                <h3>{doctorWeb ? t('Your appointment will be available in') : t('Your doctor will be available in')}</h3>
                <div className="alert alert--success">
                    <h6 className="text-black">
                        <RemainingTime t={t} remainingTime={remainingTime} />
                    </h6>
                </div>
                <p className="text-gray">{t(`Attendees can join ${isHour ? priorInHour : priorInMinute} ${isHour ? 'hour' : 'minutes'} prior to the schedule start time.`)}</p>
                <p className="text-gray">{t(`If the consultation does not become available ${isHour ? priorInHour : priorInMinute} ${isHour ? 'hour' : 'minutes'} prior to the start time, please reload the page.`)}</p>
            </div>
        </div>
    );
}

export default NotStartedYet;