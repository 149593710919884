import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';
import { OkaHMAC } from 'okadoc-libs';

const kongHMAC = new OkaHMAC(process.env.REACT_APP_KONG_SERVICES_CONFIG);

export default class Service {

    serviceInstance = false

    defHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Client-ID': process.env.REACT_APP_CLIENT_ID,
        'APP-VERSION': process.env.REACT_APP_VERSION,
        'Accept-Language': 'en',
        'Country-ID': 'ae'
    }

    constructor(options = {}) {
        const { headers = {}, baseURL = false, ...rest } = options;
        // set service configurations ...
        const requestConfig = {
            ...rest,
            headers: { ...this.defHeaders, ...headers },
            baseURL: baseURL || process.env.REACT_APP_LOCALE_API_URL,
        };

        this.initialize(requestConfig);
    }

    // initialize request instances ...
    initialize = (options = {}) => {
        const { name: serviceName } = options;
        this.serviceInstance = axios.create(options);

        // modify request config before send it, like adding kong-headers and token ...
        this.serviceInstance.interceptors.request.use(config => {
            // Kong HMAC Handlers
            config.name = serviceName;
            kongHMAC.updateHeaders(config);

            return config;
        });
    }

    // modify common request's methods
    // you might add other methods such us 'head|patch|link|unlink'
    // then just add them just like written below

    // get method
    get = (...args) => this.serviceInstance.get(...args)
    post = (...args) => this.serviceInstance.post(...args)
    update = (...args) => this.serviceInstance.update(...args)
    put = (...args) => this.serviceInstance.put(...args)
    delete = (...args) => this.serviceInstance.delete(...args)

}