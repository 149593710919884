import { Cookies } from 'react-cookie';
import _get from 'lodash/get';

const cookies = new Cookies();

export const getCookie = () => {
    const data = cookies.get(process.env.REACT_APP_COOKIE_TOKEN);

    return data;
};

export const getToken = () => {
    const value = getCookie();
    const token = _get(value, 'tK') || '';

    return token;
};
