export const isFirefox = () => navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export const isOkadocApp = () => /okadoc/i.test(navigator.userAgent.toLowerCase());

export const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const isChrome = () => /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export const chromeVersion = () => {
    const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
}