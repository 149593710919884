import i18next from 'i18next';
import store from 'store';
import expired from 'store/plugins/expire';
import { initReactI18next } from "react-i18next";
import to from 'await-to-js';
import { getLanguage } from './libs/api';
import _isEmpty from 'lodash';

store.addPlugin(expired);
i18next
    .use(initReactI18next)
    .init({
        lng: 'en',
        resources: {},
        react: {
            wait: true
        }
    });

const load = async(country, lang, token) => {
    const expiredDate = new Date() + (60 * 60 * 1000)
    const languages = store.get('locale', {});
    i18next.changeLanguage(lang);
    if (!_isEmpty(languages[lang])) {
        i18next.addResourceBundle(lang, 'common', languages[lang]);
    } else {
        const [err, resp] = await to(getLanguage(country, lang, token));
        if (!err) {
            languages[lang] = resp.data;
            i18next.addResourceBundle(lang, 'common', resp.data);
            store.set('locale', languages, expiredDate);
        }
    }
}

export { 
    load
};
export default i18next;