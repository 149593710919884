import styled from 'styled-components';
import _get from 'lodash/get';
import { tint, darken } from 'polished';

const defaultColor = {
    bg_filter: "#2347e2",
    bg_header: "#ffffff",
    bg_landing: "#ffffff",
    bg_profile: "#ffffff",
    button_cta: "#2347e2",
    text_button_cta: "#ffffff",
    text_main: "#2347e2"
};

export const Link = styled.a`
    &&& {
        color: ${props => _get(props, 'themes.text_main') || defaultColor.text_main};
        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
            color: ${props => _get(props, 'themes.text_main') || defaultColor.text_main};
        }
    }
`;

export const LinkButtonGradient = styled.a.attrs(() => ({
    className: "button"
}))`
&&& {
    background: ${props =>
        _get(props, 'themes.button_cta_gradient') || defaultColor.button_cta
    };
    color: ${props => tint(0.8, _get(props, 'themes.button_cta') || defaultColor.button_cta)};
    border-color: ${props => darken(0.1, _get(props, 'themes.button_cta') || defaultColor.button_cta)};
    &:hover,
    &:active,
    &:focus {
        filter: grayscale(0.3);
    }
    :disabled {
        color: #fff;
    }
}
`;

export const Button = styled.button.attrs(() => ({
    className: "button"
}))`
&&& {
    background: ${props =>
        _get(props, 'themes.button_cta') || defaultColor.button_cta
    };
    color: ${props => tint(0.8, _get(props, 'themes.button_cta') || defaultColor.button_cta)};
    border-color: ${props => darken(0.1, _get(props, 'themes.button_cta') || defaultColor.button_cta)};
    &:hover,
    &:active,
    &:focus {
        background: ${props => darken(0.1, _get(props, 'themes.button_cta') || defaultColor.button_cta)};
        border-color: ${props => darken(0.1, _get(props, 'themes.button_cta') || defaultColor.button_cta)};
        color: ${props => tint(0.8, _get(props, 'themes.button_cta') || defaultColor.button_cta)};
    }
    :disabled {
        color: #fff;
    }
}
`;

export const ButtonGradient = styled.button.attrs(() => ({
    className: "button"
}))`
&&& {
    background: ${props =>
        _get(props, 'themes.button_cta_gradient') ?
        _get(props, 'themes.button_cta_gradient')
        : _get(props, 'themes.button_cta') || defaultColor.button_cta
    };
    color: ${props => tint(0.8, _get(props, 'themes.button_cta') || defaultColor.button_cta)};
    border-color: ${props => darken(0.1, _get(props, 'themes.button_cta') || defaultColor.button_cta)};
    &:hover,
    &:active,
    &:focus {
        filter: grayscale(0.3);
    }
    :disabled {
        color: #fff;
    }
}
`;