import Service from './service';

export default class Appointment extends Service {

    constructor(options = {}) {
        options.name = 'appointment';
        // default base url for appointment api
        options.baseURL = process.env.REACT_APP_APPOINTMENT_API_URL;
        super(options);
    }

    // add others methods here...
}