import React from "react";
import Png from "../../components/Png";
import VideoComponent from "../../components/video";
import  { ButtonGradient } from '../../libs/themes';

class VideoCall extends React.Component {
    render() {
        const { 
            mainPublisher,
            subscribers,
            onMinimizeVideo,
            user,
            practitioner,
            patient,
            counter,
            minimizeVideo,
            onVideoActive,
            onAudioActive,
            onLeave,
            doctorWeb,
            t,
            themes
        } = this.props;

        const withSubscribers = subscribers.length > 0;
        const remoteUser = !doctorWeb ? practitioner : patient;
        return (
            <div className={`video-call ${minimizeVideo ? '' : 'active'}`}>
                <div className="video-call__navbar">
                    {!doctorWeb &&
                        <ButtonGradient 
                            themes={themes}
                            className="video-call__navbar-button green minimize-desktop"
                            onClick={onMinimizeVideo}
                            >
                            <Png
                                name="arrow-down"
                                className="img-responsive img-white"
                                alt="minimize"
                                width="18px"
                                />
                        </ButtonGradient>
                    }
                    <div className="video-call__navbar-info">
                        <h6 className="text-truncate">
                            {!doctorWeb ? remoteUser.title : [remoteUser.first_name, remoteUser.last_name].join(" ")}
                            </h6>
                        <p>
                            <small>{counter}</small>
                        </p>
                    </div>
                    <button
                        className="video-call__navbar-button button green minimize-desktop"
                        onClick={onMinimizeVideo}>
                        {t(minimizeVideo? 'Back to Video': 'Back to Chat')}
                    </button>
                    <button className="video-call__navbar-button button switch-camera">
                        <Png
                            name="ic-switch-camera"
                            className="img-responsive"
                            alt="attachment"
                            width="20px"
                            />
                    </button>
                </div>
                <div className={`video-call__user ${withSubscribers ?'video-call__user--minimize' : ''}`}>
                    <VideoComponent
                        videoID="local-video"
                        streamManager={mainPublisher}
                    />
                </div>
                {withSubscribers && (
                    <div className="video-call__user">
                        {subscribers.map((s, i) => (
                            <VideoComponent
                                key={i}
                                videoID={`remote-${i}`}
                                streamManager={s}
                            />
                        ))}
                    </div>
                )}
                <div className="video-call__action">
                    <button 
                        className="button"
                        onClick={onVideoActive}
                        >
                        <Png
                            name={user.isVideoActive() ? "ic-video-camera" : "ic-video-camera-disabled"}
                            className="img-responsive"
                            alt="mute video"
                            width="60px"
                            />
                    </button>
                    <button 
                        className="button"
                        onClick={() => {
                            onLeave()
                        }}
                        >
                        <Png
                            name="ic-hang-up"
                            className="img-responsive"
                            alt="end call"
                            width="60px"
                            />
                    </button>
                    <button 
                        className="button"
                        onClick={onAudioActive}
                        >
                        <Png
                            name={user.isAudioActive() ? "ic-mic" : "ic-mic-disabled"}
                            className="img-responsive"
                            alt="mute voice"
                            width="60px"
                            />
                    </button>
                </div>
            </div>
        );
    }
}

export default VideoCall;