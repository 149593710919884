import getOS from '../getOS';
/**
 * 
 * @param {Object} appointment data appoinment include { appointmentId, appointment_number }
 * @param {*} isPractitioner check is doctor or patient
 * @param {*} domain domain referer from whitelabel
 * @returns 
 */
export const getJistsiURL = (appointment, isPractitioner, { domain, country, lang, doctorId, facilityId }) => {
  const { appointmentId, appointment_number } = appointment;

  let jitsiUrl = `${process.env.REACT_APP_APPOINTMENT_API_URL}/telemedicine/room/${appointmentId || appointment_number}`;

  if (isPractitioner) {
    jitsiUrl = `${jitsiUrl}/doctor`
  }

  if (domain || process.env.REACT_APP_WEB_URL) {
    const domainTarget = `https://${domain || process.env.REACT_APP_WEB_URL}`
    const referer = isPractitioner
      ? `${domainTarget}/doctors/${doctorId}/profile/consultations?facilityId=${facilityId}&appointment_number=${appointmentId}`
      : `${domainTarget}/${lang}-${country}/appointment/session/${appointmentId}`

    jitsiUrl = `${jitsiUrl}?referer=${referer}`;
  }

  return jitsiUrl;
};

export const getJistsiInAppURL = (appointment, joinUrl) => {
  const { appointmentId, appointment_number } = appointment;

  const base = joinUrl && joinUrl === '' ? process.env.REACT_APP_JITSI_URL : `https://${joinUrl}`;
  const jitsiInAppURL = `${base}/${appointmentId || appointment_number}`;

  return jitsiInAppURL;
}

export const isInAppIframe = keycode => {
  const isOkadocApp = /okadoc/i.test(window.navigator.userAgent.toLowerCase());
  const isOutsystem = keycode && (/bupa/i).test(keycode);
  const isAndroidOrIOS = ["android", "ios"].indexOf(getOS()) >= 0;
  const isInApp = !!(isAndroidOrIOS && (isOkadocApp || isOutsystem));

  return isInApp
}
