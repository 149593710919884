class User {
    id;
    jitsyId;
    appointmentNumber;
    connectionId;
    withVideo;
    audioActive;
    videoActive
    screenShareActive;
    nickname;
    streamManager;
    chats;
    avatar;
    type; // 'remote' | 'local'
    room;

    constructor() {
        this.connectionId = '';
        this.withVideo = false;
        this.audioActive = true;
        this.videoActive = true;
        this.screenShareActive = false;
        this.nickname = '';
        this.streamManager = null;
        this.type = 'patient';
        this.chats = [];
        this.id = 0;
        this.appointmentNumber = 0;
        this.avatar = false;
        this.room = null;
        this.jitsyId = 0;
    }

    getAvatar() {
        return this.avatar;
    }

    getID() {
        return this.id;
    }

    getAppointmentNumber() {
        return this.appointmentNumber;
    }

    getChatHistory() {
        return this.chats;
    }

    isWithVideo() {
        return this.withVideo;
    }

    isAudioActive() {
        return this.audioActive;
    }

    isVideoActive() {
        return this.videoActive;
    }

    isScreenShareActive() {
        return this.screenShareActive;
    }

    getConnectionId() {
        return this.connectionId;
    }

    getNickname() {
        return this.nickname;
    }

    getInitial() {
        return this.nickname.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g).join("");
    }

    getStreamManager() {
        return this.streamManager;
    }

    getType() {
        return this.type;
    }

    getRoom() {
        return this.room;
    }

    getJitsyID() {
        return this.jitsyId;
    }

    setAvatar(avatar) {
        this.avatar = avatar;
    }

    setID(id) {
        this.id = id;
    }
    setAppointmentNumber(appointmentNumber) {
        this.appointmentNumber = appointmentNumber;
    }
    setChatHistory(messages) {
        this.chats = messages;
    }
    setWithVideo(isWithVideo) {
        this.withVideo = isWithVideo;
    }
    setAudioActive(isAudioActive) {
        this.audioActive = isAudioActive;
    }
    setVideoActive(isVideoActive) {
        this.videoActive = isVideoActive;
    }
    setScreenShareActive(isScreenShareActive) {
        this.screenShareActive = isScreenShareActive;
    }
    setStreamManager(streamManager) {
        this.streamManager = streamManager;
    }

    setConnectionId(conecctionId) {
        this.connectionId = conecctionId;
    }
    setNickname(nickname) {
        this.nickname = nickname;
    }
    setType(type) {
        this.type = type;
    }
    setRoom(room){
        this.room = room
    }
    setJitsyId(jitsyId){
        this.jitsyId = jitsyId
    }
}

export default User;