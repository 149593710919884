import React from "react";
import Png from "../../components/Png";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import  { ButtonGradient } from '../../libs/themes';

const dayUtc = dayjs.extend(utc);

class ChatboxHeader extends React.PureComponent {
    state = {
        counting: 0
    }
    interval = null;
    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState((state) => ({
                counting: state.counting + 1
            }));
        }, 1000);
    }

    componentWillMount() {
        clearInterval(this.interval);
    }
    render() {
        const {
            user,
            doctorWeb,
            practitioner = {},
            patient = {},
            t,
            themes,
            sessionClosed,
            appointment
        } = this.props;
        
        const remoteUser = !doctorWeb ? practitioner : patient;
        const userName = !doctorWeb ? remoteUser.title : [remoteUser.first_name, remoteUser.last_name].join(" ");
        const avatar = !doctorWeb && remoteUser.profilePicture ? remoteUser.profilePicture : (remoteUser.photo ? remoteUser.photo : (remoteUser.profilePicture ? remoteUser.profilePicture : false));
        const notStartedYet = dayUtc(appointment.start).local().unix() >= dayUtc().local().unix();  
        
        return (
            <div
                className={`chatbox-header`}>
                <div className="container">
                    <div className="chatbox-header-menu">
                        {
                            !doctorWeb && (<button 
                                            // themes={themes}
                                            className="button clear align-items-center" onClick={() => {
                                                this.props.onBackButtonClick();
                                            }}>
                                                <Png name="arrow-left" alt="back arrow" width="10px"/>
                                        </button>)
                        }
                        <div className="chatbox-header-menu__info">
                            <div className="thumbnail">
                                {
                                    avatar && (
                                        <Png
                                            external
                                            name={avatar}
                                            alt="doctor profile"
                                            className="thumbnail__image"
                                            width="10px"/>
                                    )
                                }
                                <Png
                                    name="doctor-profile-default"
                                    alt="doctor profile"
                                    className="thumbnail__image"
                                    width="10px"/>
                            </div>
                            <div className="chatbox-header-menu__info-text">
                                <h6 className="text-truncate">
                                    {userName}
                                </h6>
                                {!doctorWeb && (
                                    <p>
                                        <small>{remoteUser.bio}</small>
                                    </p>
                                )}
                            </div>
                        </div>
                        {!sessionClosed &&
                            <div className="chatbox-header-menu__button-group">
                                {user.isWithVideo() ? (
                                    <ButtonGradient
                                        themes={themes}
                                        disabled={sessionClosed}
                                        className="button red"
                                        onClick={() => {
                                            this
                                                .props
                                                .onLeave();
                                        }}>
                                        <Png
                                            name="ic-hang-up-2"
                                            alt="video camera"
                                            width="15px"
                                            className="img-responsive img-white"
                                        />
                                        <span className="ml-1">{t('End Video Call')}</span>
                                    </ButtonGradient>
                                ) : (
                                    <ButtonGradient
                                        disabled={sessionClosed}
                                        themes={themes}
                                        className="button green"
                                        onClick={() => {
                                            // console.log('asdsad');
                                            this
                                                .props
                                                .onVideoCallClick();
                                        }}>
                                        <Png
                                            name="ic-video"
                                            alt="video camera"
                                            width="15px"
                                            className="img-responsive img-white"
                                            />
                                        <span className="ml-1">{t('Video Call')}</span>
                                    </ButtonGradient>
                                )}

                                { doctorWeb &&
                                    <button
                                        // disabled={notStartedYet}
                                        className="button red"
                                        onClick={() => {
                                            this
                                                .props
                                                .onEndSession();
                                        }}>
                                        <span>{t('End Consultation')}</span>
                                    </button>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

ChatboxHeader.defaultProps = {
    t: (t) => {
        return t;
    }
}

export default ChatboxHeader;