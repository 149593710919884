const logs = [];
const isValidDomain = (domain) => {
    if (!domain) return false;
    let whitelistDomain;
    try {
        whitelistDomain = JSON.parse(process.env.REACT_APP_DOMAINS);
    } catch (error) {
        whitelistDomain = [];
    }
    return whitelistDomain.find((a) => a === domain);
}

const isDoctorDomain = (domain) => {
    let doctorDomains;
    try {
        doctorDomains = JSON.parse(process.env.REACT_APP_DOCTOR_DOMAINS);
    } catch (error) {
        doctorDomains = [];
    }
    return doctorDomains.find((a) => a === domain);
}

const addKonami = (callback) => {
    var allowedKeys = {
        37: 'left',
        38: 'up',
        39: 'right',
        40: 'down',
        65: 'a',
        66: 'b'
    };
    var konamiCode = ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a'];
    var konamiCodePosition = 0;
    document.addEventListener('keydown', function(e) {
        var key = allowedKeys[e.keyCode];
        var requiredKey = konamiCode[konamiCodePosition];
        if (key === requiredKey) {
            konamiCodePosition++;
            if (konamiCodePosition === konamiCode.length) {
                callback.call();
                konamiCodePosition = 0;
            }
        } else {
            konamiCodePosition = 0;
        }
    });
}

const log = (...args) => {
    if (process.env.REACT_APP_NODE_ENV === 'developement') {
        console.log(...args);
    } else {
        logs.push(...args);
    }
}

const getVideoCallProvider = () => {
    return process.env.REACT_APP_VIDEO_CALL_PROVIDER || "jitsi";
}

const getConfig = (country) =>  {
    let config;
    try {
        config = JSON.parse(process.env.REACT_APP_JITSI_CONFIG);
    } catch (e){
        config = {};
    }
    return config[country] || {};
}

export {
    log,
    logs,
    isValidDomain,
    isDoctorDomain,
    addKonami,
    getVideoCallProvider,
    getConfig
}