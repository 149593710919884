export const options =  {
    hosts: {
        domain: 'meet-uae.okadoc.com',
        muc: 'conference.meet-uae.okadoc.com',
    },
    bosh:'https://meet-uae.okadoc.com/http-bind',
    clientNode: 'http://jitsi.org/jitsimeet'
}

export const confOptions = {
    openBridgeChannel: true
}

export const initOptions = {
    disableAudioLevels: true,

    // The ID of the jidesha extension for Chrome.
    desktopSharingChromeExtId: 'mbocklcggfhnbahlnepmldehdhpjfcjp',

    // Whether desktop sharing should be disabled on Chrome.
    desktopSharingChromeDisabled: false,

    // The media sources to use when using screen sharing with the Chrome
    // extension.
    desktopSharingChromeSources: [ 'screen', 'window' ],

    // Required version of Chrome extension
    desktopSharingChromeMinExtVersion: '0.1',

    // Whether desktop sharing should be disabled on Firefox.
    desktopSharingFirefoxDisabled: true
};