import React, { Fragment } from "react";
import classNames from "classnames";
import Png from "./Png";

class Modal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.setModalRef = this.setModalRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setModalRef(node) {
        this.modal = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        const { toggle } = this.props;
        if (this.modal && !this.modal.contains(event.target)) {
            toggle()
        }
    }

    render() {
        const { className, children, show, withCloseButton, toggle } = this.props;

        if (show === false) return null;

        return (
            <div
                role="dialog"
                tabIndex="-1"
                className={`modal ${classNames({ [className]: className })}`}
                style={ show && { display: 'flex' } }
                >
                <div className="modal-dialog">
                    <div className="modal-content" ref={this.setModalRef}>
                        { withCloseButton &&
                            <div className="modal-close">
                                <button 
                                    className="button modal-close__button"
                                    onClick={toggle}
                                    >
                                    <Png
                                        name="ic-close"
                                        className="modal-close__icon"
                                        />
                                </button>
                            </div>
                        }
                        <Fragment>
                            {children}
                        </Fragment>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;