import Locale from './locale';
import Pigeon from './pigeon';
import Account from './account';
import Appointment from './appointment';

export default class ApiServices {

    constructor(options = {}) {
        // initialize all services instance
        this.Locale = new Locale(options);
        this.Pigeon = new Pigeon(options);
        this.Account = new Account(options);
        this.Appointment = new Appointment(options);
    }
}

export const ApiRequest = new ApiServices();