import to from 'await-to-js';

// import { isValidDomain, isDoctorDomain, log } from '../../libs/domain';
import { uploadImage, getToken, getChatHistory } from '../../libs/api';

export const onUploadImage = async(appointmentNumber, fileContent, authToken) => {

    const [err, resp] = await to(uploadImage(appointmentNumber, fileContent, authToken));
    if (err) {
        throw err;
    }

    return resp;
}

export const joinSession = async(appointmentNumber, authToken) => new Promise( async(resolve, reject) => {
    const [err, tokenData] = await to(getToken(appointmentNumber, authToken));
    if (err) {
        reject(err);
    }

    return resolve(tokenData);
});

export const getMessageHistory = async (appointmentNumber, token) =>  new Promise(async(resolve, reject) => {
    const [err, resp] = await to(getChatHistory(appointmentNumber, token));
    if (err) {
        reject(err);
    }
    resolve(resp);
});